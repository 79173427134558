import { useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { block } from "bem-cn";

import Layout from 'layouts/';
import Heading from 'components/Heading';
import MediumContainer from "components/Container/_medium";

import './style.scss'

const cn = block("cookie-declaration-page");


const SCRIPT_ID = 'CookieDeclaration';

const CookieDeclaration = ({ data }) => {
	const { seo, title } = data.markdownRemark.frontmatter;
	const cookieBotDeclaration = useRef(null);
	const [reRender, setReRender] = useState(false);

	const fetchDeclaration = useCallback(() => {
		const declarationContainer = cookieBotDeclaration.current;
		const script = document.createElement('script');
		script.setAttribute('id', SCRIPT_ID);
		script.setAttribute(
			'src',
			'https://consent.cookiebot.com/a4db0e0a-e4b7-4d08-a7e1-8ceece8eda45/cd.js'
		);
		script.setAttribute('type', 'text/javascript');
		declarationContainer && declarationContainer.appendChild(script);
	}, []);

	useEffect(() => {
		window.addEventListener('load', fetchDeclaration);
		return () => window.removeEventListener('load', fetchDeclaration);
	}, [fetchDeclaration]);

	// useEffect(() => {
	// 	setReRender(true)
	// 	return () => {
	// 		fetchDeclaration();
	// 		setReRender(false)
	// 	}
	// }, [cookieBotDeclaration, fetchDeclaration, reRender]);

	return (
		<Layout seo={seo}>
			<MediumContainer>
				<Heading color='general' weight='bold' className={cn("title")} >
					{title}
				</Heading>
				<div
					ref={cookieBotDeclaration}
					id='cookieBotDeclaration'
					className='cookie-declaration'
				/>
			</MediumContainer>
		</Layout>
	);
};

export default CookieDeclaration;

export const query = graphql`
	query getCookieDeclarationPageData {
		markdownRemark(frontmatter: { slug: { eq: "/cookie-declaration" } }) {
			frontmatter {
				title
				seo {
					description
					image
					link
					title
				}
			}
		}
	}
`;
