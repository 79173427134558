import PropTypes from "prop-types";
import { block } from "bem-cn";

import Container from "..";

import "./style.scss";

const cn = block("main-medium-container");

const MediumContainer = ({
  children,
  className,
  generalContainerClassName,
}) => {
  return (
    <Container
      className={cn("wrap").mix(className)}
      generalContainerClassName={generalContainerClassName}
    >
      {children}
    </Container>
  );
};

MediumContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
  generalContainerClassName: PropTypes.object,
};

export default MediumContainer;
